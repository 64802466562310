#our-projects {
   background-color: #F7F7F7;
   min-height: 100vh;
   background-size: cover;
   background-position: center;
   padding: 16px;

   /* z-index: -1; */
   position: relative;
}
.title {
   font-size: 30px;
   font-family: "MyriadPro bold";
   color: #326295;
}
