#panel1a-header {
   min-height: 100px;
   align-items: self-start;
   padding: 10px;
}

.panel1a-hovered {
   background-color: #e6e7e8 !important;
}

.accordion .label {
   color: #29588c;
   font-size: 24px;
   font-family: "MyriadPro";
}
.accordion .active {
   color: #fff;
}

.image {
   height: 350px;
   object-fit: cover;
   position: absolute;
   top: 0;
   transform: translateX(-55px);
   opacity: 0;
   transition: all 500ms ease;
   z-index: -1;
}
.show {
   opacity: 1;
   transform: translateX(0px);
}

.icon {
   border: 1px solid gray;
   border-radius: 3px;
}
@media screen and (max-width: 600px) {
   .image {
      display: none;
   }
}
