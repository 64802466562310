#about-us {
   background: white;
   min-height: 100vh;
}
.subHeader{
   color: #29588c;
   font-family: "MyriadPro";
   font-size: 28px;
   text-align: center;
}

.detail p{
   font-family: "MyriadPro";
   color: #29588c;
   font-size: 18px;
   text-align: justify;
}