#contact-us {
   background: url("../../assets/images/background.png");
   /* min-height: 100vh; */
   background-size: cover;
   background-position: center;
   padding: 16px;
}
.info {
   color: #29588c;
   font-size: 24px;
   font-family: "MyriadPro";
}

.info span {
   font-family: "MyriadPro bold";
}

.outlined-textarea {
   background: white;
   width: 100%;
   margin-bottom: 15px !important;
   border-color: #ffac2a !important;
}
.outlined-btn {
   background: #ffac2a !important;
   color: white !important;
   width: 100%;
   height: 82%;
   top: -5px;
}
