.outlined-btn {
    position: relative;
    transition: all 500ms ease;
    background-color: white !important;
    width: 100%;
    border: 1px solid #ffac2a !important;
    color: #ffac2a;
    text-align: center;
    padding: 8px;
    margin-top: 30px;
    cursor: pointer;
 }
 .outlined-btn::after {
    content: url(../../assets/images/arrow.svg);
    position: absolute;
    right: 55px;
    top: 25%;
    opacity: 0;
    transition: all 1000ms ease;
}
 .outlined-btn:hover {
    text-align: left;
    color: #29588c !important;
    border: 1px solid #29588c !important;
 }

 .outlined-btn:hover::after {
    right: 10px;
    opacity: 1;
}