.transparent {
   /* position: absolute; */
   /* background-color: transparent !important; */
   top: 0;
   width: 100%;
   z-index: 123;
}
.nav-text {
   font-size: 18px;
   color: white;
   font-family: "MyriadPro";
   margin: 15px;
   padding-right: 1rem !important;
   padding-left: 1rem !important;
   transition: all 500ms ease;
   transform: skew(0deg);
   height: min-content;
}
.nav-text:hover {
   transform: skew(-10deg);
   border-radius: 5px;
   background: #e6e7e8 !important;
   color: #29588c !important;
}
.nav-dropdown {
   background: #326295;
   position: relative;
   transform: skew(-10deg);
   /* border-radius: 10px; */
   border-radius: 10px 10px 0 0;
}
.nav-dropdown .nav-text{
   transform: skew(10deg) !important;
}
.nav-dropdown .MuiPaper-root {
   background: transparent;
   box-shadow: none;
}
