@import "bootstrap/dist/css/bootstrap.min.css";
@import "./assets/fonts/fonts.css";

body {
   overflow-x: hidden;
   position: relative;
}

#demo-popup-menu {
   position: relative;
   z-index: 12;
}

.navbar-toggler-icon {
   background-image: url("./assets/images/bar.svg") !important;
}

#demo-popup-menu .MuiPaper-root.MuiPopover-paper {
   background: #326295;
   position: absolute;
   z-index: 100000;
   width: 100%;
   border-radius: 0 0 10px 10px;
   overflow: hidden;
}

#demo-popup-menu .MuiPaper-root.MuiPopover-paper .MuiMenuItem-root {
   color: white !important;
   font-size: 15px;
   position: relative;
   padding-bottom: 2px;
}

#demo-popup-menu .MuiPaper-root.MuiPopover-paper .MuiMenuItem-root::after {
   content: url(./assets/images/arrow.svg);
   top: 8px;
   position: absolute;
   right: 55px;
   opacity: 0;
   transition: all 1000ms ease;
   transform: scale(0.7);
}

#demo-popup-menu .MuiPaper-root.MuiPopover-paper .MuiMenuItem-root:hover {
   color: #29588c !important;
   background-color: #f7f7f7;
}

#demo-popup-menu .MuiPaper-root.MuiPopover-paper .MuiMenuItem-root:hover::after {
   right: 10px;
   opacity: 1;
}

.Mui-expanded {
   background-color: #29588c !important;
}

/* // tabs color */
.MuiButtonBase-root {
   color: #ffac2a;
   font-family: "MyriadPro bold" !important;
}

.MuiTabs-indicator {
   background-color: #ffac2a !important;
}

/* //progress */
.MuiMobileStepper-root {
   background-color: transparent !important;
   width: 200px;
}

.MuiMobileStepper-root .MuiButtonBase-root {
   color: #29588c;
   font-family: "MyriadPro" !important;
}

.MuiMobileStepper-root .MuiButtonBase-root:first-of-type {
   margin-bottom: 30px;
}

.MuiMobileStepper-root .MuiButtonBase-root:last-of-type {
   margin-top: 30px;
}

.MuiLinearProgress-barColorPrimary {
   background-color: #ffac2a;
}

/* //slider */
.rec-slider-container {
   margin: 0 !important;
}

.MuiDrawer-root .MuiBackdrop-root {
   background-color: transparent !important;
}

.MuiDrawer-paperAnchorLeft {
   width: 30vw;
   overflow: visible !important;
   box-shadow: none !important;
   background: #e6e7e800 !important;
   overflow-x: hidden;
}

.MuiCardActionArea-focusHighlight {
   opacity: 0 !important;
}

.collapse.show {
   background: #000000db;
   /* // padding-bottom:50px; */
   flex-grow: 0.5;
   flex-basis: 50%;
   text-align: end;
   margin-left: auto;
}

.navbar-collapse {
   flex-grow: 0.5;
   flex-basis: 50%;
   text-align: end;
   margin-left: auto;
}

.pointer {
   cursor: pointer;
}