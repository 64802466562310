#our-services {
   background: url("../../assets/images/background.png");
   min-height: 100vh;
   background-size: cover;
   background-position: center;
   padding: 16px;
   padding-bottom: 200px;
   margin-bottom: 25px;
   position: relative;
}
