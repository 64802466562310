#about-us {
   background: white;
   min-height: 100vh;
}
.info p {
   font-family: "MyriadPro";
   color: #29588c;
   font-size: 18px;
   text-align: justify;
}
.detail{
    padding-block:20px;
}
.detail h4{
   font-family: "MyriadPro bold";
    color: #29588C;
   font-size: 1.9em;
   margin-bottom: 20px;
   text-align: left;
}
.detail p{
   font-family: "MyriadPro";
   color: #29588C;
   font-size: 18px;

}