.right-side {
   background-color: #e6e7e8;
   transform: skew(-10deg);
   border-radius: 8px;
}
.left-side {
   background-color: #ffac2a;
   transform: skew(-10deg);
   border-radius: 8px;
   max-width: 6%;
}
@media screen and (max-width: 500px) {
   .left-side,
   footer img,
   footer svg,
   .right-side,
   .link {
      transform: skew(0deg) !important;
   }
}
.link {
   color: #29588c;
   font-family: "MyriadPro";
   width: auto;
   padding-inline: 25px;
   cursor: pointer;
   transform: skew(10deg);
   margin-block: 10px;
   font-size: 14px;
   margin-top: 16px;
}

footer img {
   width: 140px !important;
   transform: skew(10deg);
}
footer svg {
   transform: skew(10deg);
}
footer p {
   text-align: center;
   font-size: 14px;
   color: #29588c;
   font-family: "MyriadPro";
   margin-bottom: 0px;
}
