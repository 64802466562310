@font-face {
    font-family: 'MyriadPro bold';
    src: url('./MyriadPro-Bold.otf') format('truetype');
 }
 
 @font-face {
    font-family: 'MyriadPro bold italic';
    src: url('./MyriadPro-BoldIt.otf') format('truetype');
 }
 
 @font-face {
    font-family: 'MyriadPro italic';
    src: url('./MyriadPro-It.otf') format('truetype');
 }
 
 @font-face {
    font-family: 'MyriadPro';
    src: url('./MyriadPro-Regular.otf') format('truetype');
 }