.card .details{
    opacity: 0;
    text-align: start;
    width: 50%;
    /* padding: 16px; */
    height:0px;
    transition: all 700ms ease;
}
.card img{
    width: 155px !important;
    object-fit: contain;
}
.card{
    background: white;
    position: relative;
    transition: all 500ms ease;
    height: 100%;
    align-items: start;
    width: 100%;
}
@media screen and (min-width:500px) {
    .card:hover{
        position: absolute;
        left: 0;
        z-index: 1;
    }
    .card:hover .details{
        opacity: 1;
        width: 80%;
        display: block;
        transform: translateX(0px);
    }
}