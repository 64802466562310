.news-details{
   background-color: #F7F7F7;
}
.name {
   color: #29588c;
   font-size: 18px;
   font-family: "MyriadPro";
   margin-bottom: 15px;
}
.details {
   color: #ffac2a;
   font-size: 24px;
   font-family: "MyriadPro";
   margin-bottom: 15px;
}
.info {
   color: #29588c;
   font-size: 20px;
   font-family: "MyriadPro";
}

.info span {
   color: #909090;
}
.next{
   background: #EAEAEA;
   color: #ffac2a;
   display: flex;
   justify-content: center;
   align-items: center;
}