.card-hover{
   transition: all 500ms ease;
   cursor: pointer;
   position: relative;
}
.more{
   height: 0;
   opacity: 0;
   transition: all 1000ms ease;
}
.card-hover::after {
   content: url(../../assets/images/arrow.svg);
   position: absolute;
   right: 55px;
   opacity: 0;
   transition: all 1000ms ease;
   top: 15px;
}
.card-hover:hover{
   transform: translateX(10px);
}
.card-hover:hover .more{
   height: 100%;
   opacity: 1;
}
.card-hover:hover::after {
   right: 10px;
   opacity: 1;
}
.title,
.desc {
   color: #29588c !important;
   font-size: 18px;
   font-family: "MyriadPro bold";
}
.desc {
   font-family: "MyriadPro";
   color: black !important;
   font-size: 14px !important;
}
