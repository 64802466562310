#about-us {
   background: url('../../assets/images/shut.webp');
   background-size: cover;
   background-position: center;
   min-height: 100vh;
   position: relative;
   z-index: -1;
}
.bg{
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: #ffffffe0;
}
.subHeader{
   color: #29588c;
   font-family: "MyriadPro";
   font-size: 28px;
   text-align: center;
}

.detail p{
   font-family: "MyriadPro";
   color: #29588c;
   font-size: 18px;
   text-align: justify;
}