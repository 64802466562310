.label {
   color: #29588c !important;
   font-size: 14px;
   font-family: "MyriadPro";
}

.date {
   font-family: "MyriadPro";
   color: black;
   font-size: 14px;
   text-align: right;
}
