.label{
    position: absolute;
    top: 0;
    width: 91%;
    left: 0;
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    color: white;
    background-color: rgba(0, 0, 0, 0.507);
    border-radius: 20px;
   padding:16px
}

.outlined-btn {
   transition: all 500ms ease;
   transform: translateY(-10px);
   opacity: 0;
}

.active {
   transform: translateY(0px);
   opacity: 1;
}
