.name {
   font-size: 16px;
   font-family: "MyriadPro bold";
   word-break: break-word;
   color: #fff;
}
.title {
   font-size: 14px;
   font-family: "MyriadPro";
   color: #ffc72c;
}

.desc {
   font-size: 12px;
   line-height: 1;
   font-family: "MyriadPro";
   word-break: break-word; 
   max-height: 50px;
   overflow: hidden;  
   text-overflow: ellipsis;
   color: #fff;
}