.title {
   font-size: 30px;
   font-family: "MyriadPro bold";
   color: #326295;
   width: fit-content;
   text-transform: uppercase;
}
@media (max-width:600px) {
   .row.title{
      font-size:20px !important;
   }
}
.moreBtn {
   background: white;
   margin-block: 25px !important;
   border: 1px solid #ffac2a !important;
   text-align: center;
   padding: 8px;
   cursor: pointer;
   color: #ffac2a;
}
.brands {
   background-color: #ffac2a;
   background-image: url("../assets/images/hand.png");
   background-size: cover;
   background-repeat: no-repeat;
   background-position: right;
}
.moreProject {
   background: transparent;
   margin-block: 25px !important;
   border: 1px solid #fff !important;
   text-align: center;
   padding: 8px;
   cursor: pointer;
   color: #fff;
   position: relative;
}
.moreProject::after {
   content: url(../assets/images/arrow.svg);
   position: absolute;
   right: 55px;
   top: 25%;
   opacity: 0;
   transition: all 1000ms ease;
}
.moreProject:hover {
   text-align: left;
   color: #29588c !important;
   border: 1px solid #29588c !important;
}
.moreProject:hover::after {
   right: 10px;
   opacity: 1;
}
.footer {
   background-image: url("../assets/images/footer.png");
   height: 500px;
   background-blend-mode: overlay;
   background-color: #08294e;
   background-size: contain;
   background-position: bottom;
}
